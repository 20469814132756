<template>
	<v-layout column fill-height>
		<v-flex display-1 my-3 shrink text-xs-center v-text="$t('settings.title_accounting_firm', { accountingFirm: accountingFirm.name })" />
		<v-flex scroll-y>
			<v-layout v-if="modules" fill-height px-5 my-2>
				<CardsList :pictures="false" :content="moduleList" />
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'AccountingFirmSettings',
	components: {
		CardsList: () => ({
			component: import('@/components/Commons/CardsList')
		})
	},
	computed: {
		...mapState({
			modules: state => state.modules.settings,
			accountingFirm: state => state.accountingFirm.selected
		}),
		moduleList: function () {
			return (
				this.modules.map(welybModule => {
					return {
						title: welybModule.title,
						subtitle: welybModule.description,
						img: welybModule.icon,
						routeName: welybModule.name,
						disabled: !welybModule.is_active
					}
				}) || []
			)
		}
	}
}
</script>

<style scoped>
.wrap .flex {
	margin: 0 5px;
}

.headline {
	margin: 5px auto;
}
</style>
